/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    convert: any;
    _conv_q: any;
  }
}

const convertPolling = () => {
  if (window.convert) {
    window._conv_q = window._conv_q || [];
    window._conv_q.push(["run", "true"]);
  }
};

export default convertPolling;
