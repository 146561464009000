import { UserListQuery } from "../users/types/userListQuery";

const adminQueryKeys = {
  all: ["admin"] as const,
  adminUser: () => [...adminQueryKeys.all, "adminUser"] as const,
  adminSearches: () => [...adminQueryKeys.all, "adminSearch"] as const,
  adminSearchByQuery: (query: string) =>
    [...adminQueryKeys.adminSearches(), { query }] as const,
  featuresFilterDataList: () =>
    [...adminQueryKeys.all, "featuresFilterDataList"] as const,
  dealerDetails: () => [...adminQueryKeys.all, "dealerDetails"] as const,
  dealerDetailById: (id: string) =>
    [...adminQueryKeys.dealerDetails(), { id }] as const,
  dealsSearch: () => [...adminQueryKeys.all, "dealsSearch"] as const,
  dealerList: () => [...adminQueryKeys.all, "dealerList"] as const,
  dealPreviewDetails: () => [...adminQueryKeys.all, "dealPreview"] as const,
  dealPreviewById: (id: string) =>
    [...adminQueryKeys.dealPreviewDetails(), { id }] as const,
  appraisals: () => [...adminQueryKeys.all, "appraisals"] as const,
  appraisalPreviewDetails: () =>
    [...adminQueryKeys.all, "appraisalPreview"] as const,
  appraisalPreviewById: (id: string) =>
    [...adminQueryKeys.appraisalPreviewDetails(), { id }] as const,
  appraisalImageList: (id: string) =>
    [...adminQueryKeys.appraisalPreviewById(id), { id }] as const,
  appraisalSearchByVin: (sourceId: number, vinQuery: string) =>
    [
      ...adminQueryKeys.appraisals(),
      "appraisalSearch",
      { sourceId, vinQuery },
    ] as const,
  dealDetails: () => [...adminQueryKeys.all, "dealDetails"] as const,
  dealDetailsById: (id: string) =>
    [...adminQueryKeys.dealDetails(), { id }] as const,
  dealCustomerCreditReport: () =>
    [...adminQueryKeys.all, "dealCustomerCreditReport"] as const,
  dealCustomerCreditReportsById: (id: string) =>
    [...adminQueryKeys.dealCustomerCreditReport(), { id }] as const,
  dealDocuments: (dealId: string) =>
    [...adminQueryKeys.all, "dealDocuments", { dealId }] as const,
  availableDocumentsForDealId: (dealId: string) =>
    [...adminQueryKeys.all, "availableDocumentsForDealId", { dealId }] as const,
  dealTodos: (dealId: string) =>
    [...adminQueryKeys.all, "dealTodos", { dealId }] as const,
  productsByDealId: (dealId: string) =>
    [...adminQueryKeys.dealDetailsById(dealId), "products"] as const,
  productConfigurationsByDealId: (dealId: string, productId: string) =>
    [
      ...adminQueryKeys.all,
      "productConfigurationsByDealId",
      { dealId, productId },
    ] as const,
  availableProductsByDealId: (dealId: string) =>
    [...adminQueryKeys.dealDetailsById(dealId), "available-products"] as const,
  aftermarketProductList: () =>
    [...adminQueryKeys.all, "aftermarketProductList"] as const,
  addedProductsByDealId: (dealId: string) =>
    [...adminQueryKeys.dealDetailsById(dealId), "added-products"] as const,
  transactionsByDealId: (dealId: string) =>
    [...adminQueryKeys.dealPreviewById(dealId), "transaction-list"] as const,
  adminTradeInContext: () => [...adminQueryKeys.all, "tradeInContext"] as const,
  todos: () => [...adminQueryKeys.all, "todos"] as const,
  tradeInVerificationItemsByDealAndTradeInId: (
    dealId: string,
    tradeInId: string
  ) =>
    [
      ...adminQueryKeys.all,
      "tradeInVerificationItems",
      { dealId, tradeInId },
    ] as const,
  payoutEmbedUrl: (dealId: string | null) =>
    [...adminQueryKeys.all, "payoutEmbedUrl", { dealId }] as const,
  customerPayoutInformation: (dealId: string) =>
    [...adminQueryKeys.all, "customerPayoutInformation", { dealId }] as const,
  searchAppraisals: () => [...adminQueryKeys.all, "searchAppraisals"] as const,
  userRoles: () => [...adminQueryKeys.all, "userRoles"] as const,
  userPermissions: () => [...adminQueryKeys.all, "userPermissions"] as const,
  userList: () => [...adminQueryKeys.all, "userList"] as const,
  userListByQuery: (query: UserListQuery) =>
    [...adminQueryKeys.userList(), query] as const,
  userDetail: (adminId: string) =>
    [...adminQueryKeys.all, "userDetail", { adminId }] as const,
  dealTransactionSecret: (dealId: string) =>
    [...adminQueryKeys.all, "dealTransactionSecret", { dealId }] as const,
};

export default adminQueryKeys;
