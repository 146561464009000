// eslint-disable-next-line import/extensions
import "intro.js/introjs.css";
import "@styles/globals.css";
import "@styles/toastify.css";
import "@styles/activeengagechat.css";
import { AppProps, NextWebVitalsMetric } from "next/app";
import {
  DehydratedState,
  HydrationBoundary,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { NextPage } from "next";
import { IPageViewTelemetry } from "@microsoft/applicationinsights-web";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { getClientAppInsights } from "@modules/core/applicationInsights/clientTelemetryService";
import { appWithTranslation } from "next-i18next";
import ToastContainer from "@common/components/ToastContainer";
import { getClientSideQueryClient } from "@modules/api/queryClients";
import Head from "next/head";
import userQueryKeys from "@modules/user/userQueryKeys";
import adminQueryKeys from "@modules/admin/user/adminQueryKeys";
import { captureException, ErrorBoundary } from "@sentry/nextjs";
import ErrorFallback from "@components/ErrorFallback";
import { User } from "@modules/user/types/user";
import { Admin } from "@modules/admin/user/types/Admin";
import convertPolling from "@modules/core/convert/convertServices";
import { volteFont } from "@common/constants/localFonts";
import nextI18NextConfig from "../../next-i18next.config";
import "@stripe/stripe-js";

type PageProps = {
  dehydratedState: DehydratedState;
};

type NextPageWithOptions = NextPage<{ dehydratedState: DehydratedState }> & {
  pageName: string;
  getLayout?: (page: ReactElement) => ReactNode;
  pageWrapper?: (page: ReactElement) => ReactElement;
};

type AppPropsWithOptions = AppProps & {
  Component: NextPageWithOptions;
  pageProps: PageProps;
};

export const reportWebVitals = (metric: NextWebVitalsMetric) => {
  const { clientAppInsights } = getClientAppInsights();

  clientAppInsights?.trackMetric({
    name: metric.name,
    properties: metric,
    average: 0,
  });
};

const MyApp = ({
  Component,
  pageProps,
  router,
}: AppPropsWithOptions): JSX.Element => {
  const {
    clientAppInsights,
    initialize: initializeAppInsights,
    reactPlugin,
  } = getClientAppInsights();
  const { pageName } = Component;
  const errorFallbackPage = () => <ErrorFallback />;

  const [queryClient] = useState(() => getClientSideQueryClient());

  // Ensure component's `pageName` is set so it doesn't change after build.
  // This is used by application insights for tracking page views.
  if (!pageName) {
    throw new Error("Component's `pageName` property is not set");
  }

  // This will trigger the Convert polling,
  // It runs all experiment conditions and monitors all page loading goals.
  useEffect(() => {
    convertPolling();
  });

  useEffect(() => {
    initializeAppInsights();
  }, [initializeAppInsights]);

  useEffect(() => {
    const { route, query } = router;

    const properties: Record<string, string | string[] | undefined> = {
      route,
    };

    if (query) {
      Object.keys(query).forEach((key: string) => {
        properties[`query.${key}`] = query[key];
      });
    }

    const pageView: IPageViewTelemetry = {
      name: pageName,
      properties,
    };

    clientAppInsights?.trackPageView(pageView);
  }, [clientAppInsights, pageName, router]);

  const noOpWrapper = (page: ReactElement) => {
    return page;
  };

  const getLayout = Component.getLayout ?? noOpWrapper;
  const getWrapper = Component.pageWrapper ?? noOpWrapper;

  const getLoggedInUserId = (): string | null => {
    let LoggedInUserId = null;
    const user = queryClient.getQueryData(userQueryKeys.user()) as User;

    if (user && user.id) {
      LoggedInUserId = user.id;
    } else {
      const admin = queryClient.getQueryData(
        adminQueryKeys.adminUser()
      ) as Admin;

      if (admin && admin.id) {
        LoggedInUserId = admin.id;
      }
    }

    return LoggedInUserId;
  };

  return (
    <>
      {/* 
        Some `meta` tags are duplicated when declared in `_document`.
        Move here instead.

        https://github.com/vercel/next.js/issues/6919
      */}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="fb:app_id" content="1401344726962497" />
      </Head>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <ErrorBoundary
              beforeCapture={(scope) => {
                const loggedInUserId = getLoggedInUserId();

                if (loggedInUserId) {
                  scope.setUser({ id: loggedInUserId });
                }
              }}
              onError={(error) => {
                captureException(error);
              }}
              fallback={errorFallbackPage}
            >
              <>
                <style jsx global>{`
                  :root {
                    --font-volte: ${volteFont.style.fontFamily};
                  }
                `}</style>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {getLayout(getWrapper(<Component {...pageProps} />))}
              </>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} />
          </HydrationBoundary>
        </QueryClientProvider>
        <ToastContainer />
      </AppInsightsContext.Provider>
    </>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
