var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"20240826.18367"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
const ignoreSentryErrors = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications.",
  "Can't find variable: $",
  "$ is not defined",
  'Could not load "util"',
  'Could not load "controls"',
  "The object can not be cloned.",
  "NotSupportedError: The operation is not supported.",
  "NotSupportedError: Failed to load because no supported source was found.",
  "t.isActive is not a function",
  "Response returned with non-OK status",
  // Gubagoo errors
  "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
  /^(NotAllowedError|AbortError): (play\(\) failed|The play method|The play\(\) request was interrupted).*$/,
  /^(TypeError:\s*)?window\.ggConfig\.get.*$/,
  // Hotjar errors
  "TypeError: Cannot read properties of undefined (reading 'site_id')",
  "TypeError: undefined is not an object (evaluating 'hj.settings.site_id')",
  // ignore gzip errors, match any pattern of: ".gzip"
  /^TypeError: undefined is not an object \(evaluating '.*\.gzip'\)$/,
  "Can't find variable: _AutofillCallbackHandler",
  "Can't find variable: __AutoFillPopupClose__",
  // See https://github.com/getsentry/sentry-javascript/issues/10011
  "Cannot read properties of undefined (reading 'domInteractive')",
  "Failed to load Stripe.js",
  "TypeError: undefined is not an object (evaluating 'a.L')",
];

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://58c6a5266fdd4883892693780226be15@o1156546.ingest.sentry.io/4504459700797440",
  tunnel: "/api/sentry/tunnel",
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.3,
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: false,
      beforeErrorSampling: (error) => {
        const errorType = error.exception?.values[0]?.type;
        const errorValue = error.exception?.values[0]?.value;
        const pageUrl = error.request?.url;

        // Capture replays if they are not from the admin page
        // Or if they are not in the ignoreSentryErrors list
        return !(
          (pageUrl && pageUrl.includes("admin")) ||
          (errorType &&
            errorValue &&
            ignoreSentryErrors.includes(`${errorType}: ${errorValue}`)) ||
          (errorValue && ignoreSentryErrors.includes(errorValue))
        );
      },
    }),
  ],
  environment: process.env.NEXT_PUBLIC_ENV_NAME,
  enabled: process.env.NEXT_PUBLIC_ENV_NAME !== "local",
  ignoreErrors: ignoreSentryErrors,
  maxValueLength: 8192, // The max
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
